import React from 'react'
import { Grid } from 'semantic-ui-react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import VIPChair from '../images/vip-chair/vip.png'
import MagnaChair from '../images/magna-chair/magna.png'
import VSportChair from '../images/vsport-chair/vsportn.png'
import TergoChair from '../images/tergo-chair/tergo.png'
import NovoChair from '../images/novo-chair/novo.png'
import NovoXChair from '../images/novox-chair/novox.png'
import MajesticChair from '../images/majestic-chair/majestic.png'

const items = [
  {
    model: 'Majestic',
    productImgLink: MajesticChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2',
      'PATENT NO.: US 10,792,218 B2',
      'PATENT NO.: US 11,083,668 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016', 'Oct 6, 2020', 'Aug 10, 2021']
  },
  {
    model: 'Magna',
    productImgLink: MagnaChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2',
      'PATENT NO.: US 10,792,218 B2',
      'PATENT NO.: US 11,083,668 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016', 'Oct 6, 2020', 'Aug 10, 2021']
  },
  {
    model: 'VIP',
    productImgLink: VIPChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2',
      'PATENT NO.: US 10,792,218 B2',
      'PATENT NO.: US 11,083,668 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016', 'Oct 6, 2020', 'Aug 10, 2021']
  },
  {
    model: 'VSport',
    productImgLink: VSportChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2',
      'PATENT NO.: US 10,792,218 B2',
      'PATENT NO.: US 11,083,668 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016', 'Oct 6, 2020', 'Aug 10, 2021']
  },
  {
    model: 'Tergo',
    productImgLink: TergoChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2',
      'PATENT NO.: US 10,792,218 B2',
      'PATENT NO.: US 11,083,668 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016', 'Oct 6, 2020', 'Aug 10, 2021']
  },
  {
    model: 'Novo',
    productImgLink: NovoChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2',
      'PATENT NO.: US 10,792,218 B2',
      'PATENT NO.: US 11,083,668 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016', 'Oct 6, 2020', 'Aug 10, 2021']
  },
  {
    model: 'NovoX',
    productImgLink: NovoXChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2',
      'PATENT NO.: US 10,792,218 B2',
      'PATENT NO.: US 11,083,668 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016', 'Oct 6, 2020', 'Aug 10, 2021']
  }
]

const IPPage = () => {
  return (
    <Layout>
      <SEO
        title="Intellectual Property"
        description="For Contégo patented technologies. Please Refer to virtual patents"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />

      <div className="container py-2">
        <h1 className="w-100 text-center py-4">INTELLECTUAL PROPERTY</h1>

        {items.map(item => {
          return (
            <div className="row p-4 my-4 rounded shadow" key={item.model}>
              <h1 className="w-100 text-center py-4 px-4 text-uppercase rounded">{item.model}</h1>

              <Grid columns="16" stackable verticalAlign="middle" className="bg-light">
                <Grid.Column width="6">
                  <img src={item.productImgLink} className="img-fluid" alt="pedicure spa chair" />
                </Grid.Column>

                <Grid.Column width="5">
                  <h2>PATENTS</h2>
                  {item.patents.map(patent => {
                    return <p>{patent}</p>
                  })}
                </Grid.Column>

                <Grid.Column width="5">
                  <h2>ISSUE DATE(s)</h2>
                  {item.issueDate.map(idate => {
                    return <p>{idate}</p>
                  })}
                </Grid.Column>
              </Grid>
            </div>
          )
        })}

        <div className="row p-4 my-4 rounded shadow">
          <h1 className="w-100 text-center py-4 px-4 text-uppercase rounded">DOUBLE LAYER LINER</h1>

          <Grid columns="16" stackable verticalAlign="middle" className="bg-light w-100 mx-auto">
            <Grid.Column width="8">
              <h2>PATENTS</h2>
              <p>PATENT NO.: US 10,792,218 B2</p>
              <p>PATENT NO.: US 11,083,668 B2</p>
            </Grid.Column>

            <Grid.Column width="8">
              <h2>ISSUE DATE(s)</h2>
              <p>Oct 6, 2020</p>
              <p>Aug 10, 2021</p>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </Layout>
  )
}

export default IPPage
